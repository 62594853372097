<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <footer>
    <div class="container">
      <div
        class="flex flex-wrap justify-content-between align-items-center footer-top"
      >
        <a href="javascript: void(0)" class="footer-logo"
          ><img :src="image" alt=""
        /></a>
        <ul class="footer-nav">
          <li>
            <span
              ><a
                href="javascript: void(0)"
                @click="$router.push({ name: 'pricing-page' })"
                >Pricing</a
              ></span
            >
            <span
              ><a
                href="javascript: void(0)"
                @click="
                  $router.push({ name: 'pricing-page', hash: '#faq-section' })
                "
                >FAQ</a
              ></span
            >
          </li>
          <li>
            <span
              ><a
                href="javascript: void(0)"
                @click="$router.push({ name: 'about-us' })"
                >About</a
              ></span
            >
            <span
              ><a
                href="javascript: void(0)"
                @click="
                  $router.push({
                    name: 'pricing-page',
                    hash: '#contact-section'
                  })
                "
                >Contact</a
              ></span
            >
          </li>
          <li>
            <span
              ><a
                href="javascript: void(0)"
                @click="$router.push({ name: 'front-login' })"
                >Login</a
              ></span
            >
          </li>
        </ul>
        <ul class="social-links">
          <li>
            <!--  <a href="facebook.com" target="_blank"><img :src="facebookIcon" width="37px" alt="facebook"/></a>
              <a href="#" target="_blank"><img :src="twitterIcon" width="37px" alt="twitter"/></a>
              <a href="#" target="_blank" ><img :src="linkedInIcon" width="37px" alt="linkedin"/></a>
 -->
            <a href="https://www.facebook.com" target="_blank"
              ><i class="fa fa-facebook"></i
            ></a>
            <a href="https://www.twitter.com" target="_blank"
              ><i class="fa fa-twitter"></i
            ></a>
            <a href="https://www.linkedin.com" target="_blank"
              ><i class="fa fa-linkedin"></i
            ></a>
          </li>
        </ul>
      </div>
      <div
        class="flex justify-content-between align-items-center copyright-wrap footer-bottom"
      >
        <p>© copyright Personhood360 2020</p>
        <ul>
          <li>
            <a
              href="javascript: void(0)"
              @click="$router.push({ name: 'terms-and-conditions' })"
              >Terms and conditions</a
            >
          </li>
          <li>
            <a
              href="javascript: void(0)"
              @click="$router.push({ name: 'privacy-policy' })"
              >Privacy policy</a
            >
          </li>
        </ul>
        <a href="https://ebpearls.com.au/website-design-sydney/" target="_blank"
          >web design by <strong>eb</strong>pearls</a
        >
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "front-footer",
  props: {},
  data() {
    return {
      image: require("../../assets/images/logo/logo.svg"),
      facebookIcon: require("../../assets/images/personhood/facebook.png"),
      twitterIcon: require("../../assets/images/personhood/twitter.png"),
      linkedInIcon: require("../../assets/images/personhood/linkedin.png")
    };
  }
};
</script>

<style lang="scss" scoped>
footer {
  background: #ededed;
  border-top: 1px solid #cdd7d6;
  padding: 30px 0 50px;
  .footer-logo {
    width: 165px;
    height: 95px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .footer-nav {
    li {
      list-style: none;
      span {
        display: block;
      }
      a {
        display: inline-block;
        color: #393c41;
        font-size: 18px;
        line-height: 35px;
        font-weight: 600;
        transition: 0.4s all ease-in-out;
        &:hover {
          color: #2d9ad5;
        }
      }
      @media (min-width: 768px) {
        margin: 0 15px;
      }
      @media (min-width: 992px) {
        margin: 0 40px;
      }
      @media (min-width: 1480px) {
        margin: 0 50px;
      }
      @media (min-width: 1640px) {
        margin: 0 85px;
      }
    }
    @media (min-width: 768px) {
      column-count: 3;
    }
  }
  .social-links {
    li {
      list-style: none;
      a {
        width: 40px;
        height: 40px;
        line-height: 36px;
        border-radius: 40px;
        border: 2px solid #2d9ad5;
        background: #2d9ad5;
        color: #ffffff;
        display: inline-block;
        text-align: center;
        margin-left: 25px;
        transition: 0.4s all ease-in-out;
        font-size: 22px;
        &:hover {
          background: none;
          color: #2d9ad5;
        }
      }
    }
  }
  .copyright-wrap {
    margin-top: 25px;
    p,
    a {
      color: #727c7b;
      font-size: 13px;
      line-height: 23px;
      transition: 0.4s all ease-in-out;
      @media (min-width: 992px) {
        font-size: 16px;
        line-height: 26px;
      }
    }
    a {
      &:hover {
        color: #2d9ad5;
      }
    }
    ul {
      li {
        border-right: 1px solid #727c7b;
        padding-right: 10px;
        margin-right: 10px;
        display: inline-block;
        a {
          line-height: 16px;
        }
        &:last-child {
          border-right: none;
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
  }
  @media (max-width: 767px) {
    padding: 20px 0;
    .footer-top {
      display: block;
      text-align: center;
      .footer-logo {
        display: inline-block;
        margin: 0 auto;
      }
      .social-links {
        width: 100%;
        text-align: center;
      }
    }
    .footer-nav {
      width: 100%;
      text-align: center;
      margin: 20px 0;
      li {
        display: inline-block;
        a {
          line-height: 38px;
        }
        span {
          display: inline-block;
          margin: 0 20px;
        }
      }
    }
    .footer-bottom {
      display: block !important;
      text-align: center;
    }
    .copyright-wrap {
      p,
      a {
        line-break: 30px;
      }
      ul {
        li {
          line-height: 0;
          a {
            line-height: 14px;
          }
        }
      }
    }
  }
}
.fa {
  font-family: "FontAwesome";
  font-style: normal;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-linkedin:before {
  content: "\f0e1";
}
</style>
