<!-- =========================================================================================
  File Name: FullPage.vue
  Description: Full page layout
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="layout--full-page">
    <front-header />
    <router-view></router-view>
  </div>
</template>


<script>

  import FrontFooter           from '@/layouts/components/FrontFooter.vue'
  import FrontHeader           from '@/layouts/components/FrontHeader.vue'

  export default {
    components: {
      FrontFooter,
      FrontHeader,
    },
    data() {
      return {

      }
    },
    watch: {

    },
    computed: {

    },
    methods: {
    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {

    }
  }

</script>
