<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <header>
    <div class="container">
      <div class="flex justify-content align-items-center">

        <div class="flex">

        <h1>
          <a href="https://www.personhood360.com" class="logo-img-wrap">
            <img :src="image" />
          </a>
        </h1>
        <!-- <ul class="header-menu">
          <li>
            <a
              href="javascript: void(0)"
              @click="$router.push({ name: 'pricing-page' })"
              >Pricing</a
            >
          </li>
          <li>
            <a
              href="javascript: void(0)"
              @click="$router.push({ name: 'about-us' })"
              >About</a
            >
          </li>
          <li>
            <a
              href="javascript: void(0)"
              @click="
                $router.push({
                  name: 'pricing-page',
                  hash: '#contact-section'
                })
              "
              >Contact</a
            >
          </li>
          <li  v-if="!isLogin">
            <a href="javascript:void()" class="btn btn-primary sm:hidden" style="padding-top: 10px; padding-bottom: 10px" @click="$router.push('/login')">
              login
            </a>
          </li>
          <li  v-else>
            <a href="javascript:void()" class="btn btn-primary sm:hidden" style="padding-top: 10px; padding-bottom: 10px" @click="logout">
              logout
            </a>
          </li>
        </ul> -->

        </div>

        <div class="sm:flex ml-auto space-x-2">
        <div v-if="!isLogin" class="ml-auto">
          <a
            href="javascript:void()"
            class="btn btn-primary"
            @click="$router.push('/login')"
            >login</a
          >
        </div>
        <div  v-else class="ml-auto">
          <a href="javascript:void()" class="btn btn-primary" @click="logout"
            >logout</a
          >
        </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions } from "vuex";
import firebase from "firebase";
export default {
  name: "front-header",
  props: {},
  data() {
    return {
      image: require("../../assets/images/logo/logo.svg"),
      isLogin: false
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },

  mounted() {
    if (this.$store.state.auth.isUserLoggedIn()) {
      this.isLogin = true;
    }
  },

  methods: {
    logout() {
      // if user is logged in via auth0
      if (this.$auth.profile) this.$auth.logOut();

      // if user is logged in via firebase
      const firebaseCurrentUser = firebase.auth().currentUser;

      if (firebaseCurrentUser) {
        firebase
          .auth()
          .signOut()
          .then(() => {
            this.$router.push("/login").catch(() => {});
          });
      }

      this.$acl.change("guest");

      // If JWT login
      if (
        localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}accessToken`
        )
      ) {
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}accessToken`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}refreshToken`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}firstName`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}tokenExpiry`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}userInfo`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}loggedIn`
        );

        if (
          this.activeUserInfo.userType == "superAdmin" ||
          this.activeUserInfo.userType == "admin"
        ) {
          this.$router.push("/admin/login").catch(() => {});
        } else {
          this.$router.push("/login").catch(() => {});
        }
      }

      // Change role on logout. Same value as initialRole of acj.js

      localStorage.removeItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}userInfo`
      );

      // This is just for demo Purpose. If user clicks on logout -> redirect

      this.isLogin = false;
      if (
        this.activeUserInfo.userType == "superAdmin" ||
        this.activeUserInfo.userType == "admin"
      ) {
        this.$router.push("/admin/login").catch(() => {});
      } else {
        this.$router.push("/login").catch(() => {});
      }
    }
  }
};
</script>

<style lang="scss" scoped>
header {
  background: #ffffff;
  padding: 5px 0 15px;
  border-bottom: 1px solid #cdd7d6;
  @media (min-width: 1200px) {
    padding: 5px 0 12px;
  }
  @media (min-width: 1640px) {
    padding: 5px 0 18px;
  }
  .logo-img-wrap {
    width: 130px;
    height: 60px;
    display: block;
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
    @media (min-width: 1200px) {
      width: 150px;
      height: 80px;
    }
    @media (min-width: 1640px) {
      width: 165px;
      height: 95px;
    }
  }
  .btn-primary {
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    color: #ffffff;
    background: #2d9ad5;
    border: 1px solid #2d9ad5;
    padding: 10px 30px;
    box-shadow: none;
    border-radius: 8px;
    display: inline-block;
    transition: 0.4s all ease-in-out;
    &:hover {
      background: #ffffff;
      color: #2d9ad5;
    }
  }

  .header-menu {
    list-style-type: none;
    margin: 0;
    margin-left: 15px;
    padding: 10px;
    overflow: hidden;
    //background-color: #333;
    li {
      float: left;
      a {
        display: block;
        //color: white;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
      }
      a:hover {
        background-color: #2d9ad5;
        color: #ffffff;
      }
    }
  }
}
</style>
